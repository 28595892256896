.eg-card {
    border-radius: 5px;
    border: 1px solid #eee;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    -webkit-background-clip: border-box;
}

.eg-btn {
    border-radius: 5px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    transition: all 0.4s ease;
    text-transform: capitalize;
    font-family: saira, sans-serif;
}



.auction-card3 {
    padding: 20px 20px 25px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.06);
    border-radius: 0 30px;
    border: unset;
}

.auction-card3:hover .auction-img {
    border-radius: 0 30px;
}

.auction-card3:hover .auction-img img {
    transform: scale(1.2);
}

.auction-card3 .auction-timer {
    border-radius: 30px;
    padding-bottom: 20px;
    text-align: center;
    background: #fff;
}

.auction-card3 .auction-timer span.timer-title {
    font-size: 14px;
    font-weight: 400;
    font-family: inter, sans-serif;
    color: #696969;
    margin-bottom: 5px;
    display: block;
}

.auction-card3 .auction-timer .countdown h4 {
    font-size: 22px;
    font-weight: 700;
    color: #029e7c;
    margin-bottom: 0;
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .auction-card3 .auction-timer .countdown h4 {
        font-size: 22px;
    }
}

@media (max-width: 1199px) {
    .auction-card3 .auction-timer .countdown h4 {
        font-size: 20px;
    }
}

.auction-card3 .auction-img {
    position: relative;
    overflow: hidden;
    border-radius: 0 30px;
    margin-bottom: 25px;
}

.auction-card3 .auction-img img {
    width: 100%;
    height: 250px;
    object-fit: contain;
    transition: all 0.65s ease;
}

.auction-card3 .auction-content h4 {
    font-size: 23px;
    font-weight: 600;
    color: #1f2230;
    line-height: 1.5;
    margin-top: -7px;
}

.auction-card3 .auction-content p {
    font-size: 16px;
    font-weight: 500;
    color: #696969;
}

.auction-card3 .auction-content p span {
    font-size: 20px;
    font-weight: 700;
    color: #1f2230;
}

.auction-card3 .auction-content .price {
    font-size: 16px;
    font-weight: 700;
    color: #32c36c;
}

.auction-card3 .auction-card-bttm {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
}

.auction-card3 .author-area {
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
}

.auction-card3 .author-area .author-emo {
    border-radius: 50%;
    background: #fff;
    padding: 7px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid transparent;
    z-index: 1;
}

.auction-card3 .author-area .author-emo img {
    width: 22px;
    height: 22px;
    line-height: 22px;
}

.auction-card3 .author-area .author-name {
    background: #029e7c;
    padding: 6px 20px 6px 44px;
    border-radius: 33px;
    margin-left: -27px;
    transform: scaleX(0.1);
    transform-origin: left;
    transition: 0.6s ease;
}

.auction-card3 .author-area .author-name span {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
}

.auction-card3 .author-area:hover .author-emo {
    border: 1px solid #32c36c;
}

.auction-card3 .author-area:hover .author-name {
    transform: scaleX(1);
}

.auction-card3 .share-area {
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    align-items: center;
}

.auction-card3 .share-area .share-btn i {
    height: 34px;
    width: 34px;
    line-height: 34px;
    border-radius: 50%;
    background-color: #eee;
    text-align: center;
    transition: 0.5s ease;
    z-index: 9;
}

.auction-card3 .share-area .share-btn i:hover {
    background: #1f2230;
    color: #fff;
}

.auction-card3 .share-area .social-icons {
    padding: 5px;
    margin-right: 0;
    transform: scaleX(0);
    transform-origin: right;
    transition: 0.5s ease;
    z-index: 1;
    margin-bottom: 0;
}

.auction-card3 .share-area:hover .social-icons {
    transform: scaleX(1);
}

.auction-card3 .share-area:hover .social-icons li {
    margin-right: 8px;
}

.auction-card3 .share-area:hover .social-icons li i {
    font-size: 1rem;
    transition: all 0.42s ease;
}

.auction-card3 .share-area:hover .social-icons li i:hover {
    color: #32c36c;
}

.auction-card3.style-2 {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
}

.auction-card3.style-2 .auction-timer .countdown h4 {
    color: #444;
}


.auction-card1:hover .auction-img img {
    transform: scale(1.2);
}

.auction-card1 .auction-img {
    position: relative;
    overflow: hidden;
}

.auction-card1 .auction-img img {
    width: 100%;
    border-radius: 5px 5px 0 0;
    transition: all 0.65s ease;
}

.auction-card1 .auction-img .auction-timer {
    border-radius: 30px;
    padding: 12px 20px;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    background: #fff;
    min-width: 230px;
}

.auction-card1 .auction-img .auction-timer .countdown h4 {
    font-size: 24px;
    font-weight: 600;
    color: #444;
    margin-bottom: 0;
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .auction-card1 .auction-img .auction-timer .countdown h4 {
        font-size: 22px;
    }
}

@media (max-width: 1199px) {
    .auction-card1 .auction-img .auction-timer .countdown h4 {
        font-size: 20px;
    }
}