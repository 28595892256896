@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;500&display=swap");

.loginbg {
  background-image: url(https://images.unsplash.com/photo-1533174072545-7a4b6ad7a6c3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
  background-size: cover;
}

body {
  background-color: #f0f7f7 !important;
}

.text-blue {
  color: #051322;
}

.jost {
  font-family: "Jost", sans-serif;
}

.t-14 {
  font-size: 14px;
}

.t-16 {
  font-size: 16px;
}

body {
  font-family: "Roboto", sans-serif;
}

.popins {
  font-family: "Poppins", sans-serif;
}

.outletheading {
  color: #071952;
  font-family: Jost, sans-serif;
  font-size: 40px;
  margin-bottom: 20px;
  text-align: center;
}

.auth_btn {
  background-color: #1976d2 !important;
  color: #fff !important;
}

.OtpBox input {
  height: 3rem !important;
  width: 3rem !important;
  border: 1px solid grey;
  border-radius: 10px;
}



.icongreen {
  color: #10da6f;
}

/* .iconbox {
  background-color: #e9fcf1;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
} */

.mobile_fullname_logo {
  height: 60px;
}

.iconbox img {
  width: 40px;
}

.sidebarContainer {
  position: fixed;
  left: 0;
  top: 0;

  /* background-color: #; */
  min-height: 100vh;
  height: 100%;
  background: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 9999;
  transition: all 0.2s;
  display: block;
  width: 220px;
}

@media (min-width: 768px) {
  .sidebarContainer {
    display: block !important;
  }

  #closeSidebar {
    display: none !important;
  }

  .beat_logo {
    width: 60px !important;
    margin-top: 6px;
  }
}

.closeSidebar {
  color: #fff;
  text-align: right;
}

.sidebarContainer.collapsed {
  width: 80px;
}

.mainContainer {
  width: calc(100% - 220px);
  margin-left: 220px;
  transition: all 0.2s;
  position: relative;
}

.mainContainer.collapsed {
  width: calc(100% - 80px);
  margin-left: 80px;
}

.sidebarContainer .sidebar-header {
  width: 100%;
  height: 70px;
  /* padding: 3px; */
  /* background-color: #051322; */
  /* border-bottom: 1px solid #ddd; */
  background-color: #f3f5f7;
  line-height: 50px;
  box-shadow: 4px 4px 30px 0px rgba(75, 102, 171, 0.2);
  transition: all ease-in-out 0.4s;
}

.sidebarContainer.collapsed .sidebar-header img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sidebarContainer.collapsed .sidebar-content {
  padding: 2rem 1rem;
  overflow: auto;
  max-height: calc(100% - 10%);
}

.sidebarContainer .sidebar-content a {
  color: #000;
  text-decoration: none;
}

.sidebarContainer .sidebar-content ul li {
  padding: 15px 0;
  position: relative;
  min-width: 80px;
}

.sidebarContainer .sidebar-content a.active .icon {
  width: 40px;
  height: 39px;
  border-radius: 4px;
  background: #4e124626;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: #264653;
  text-align: center;
  line-height: 36px;
}

.sidebarContainer .sidebar-content a.active .icon {
  background-color: #32c36c !important;
  color: white;
}

.mobilelogo {
  display: none;
}

.sidebar-content a .icon img {
  width: 28px;
  height: 28px;
  object-fit: contain;
}

.sidebarContainer:not(.collapsed) .sidebar-content {
  padding: 1rem 1rem;
  max-height: calc(100% - 10%);
  overflow: auto;
}

/* Adjust the scrollbar width and color */
.sidebarContainer:not(.collapsed) .sidebar-content::-webkit-scrollbar,
.sidebarContainer.collapsed .sidebar-content::-webkit-scrollbar {
  width: 4px;
  /* Adjust the width as needed */
}

.overflowscroll::-webkit-scrollbar {
  width: 2px;
  height: 5px;

}

.overflowscroll::-webkit-scrollbar-thumb {
  background-color: #d3d9e0;
  /* Change the color as needed */
  border-radius: 10px;
}

.overflowscroll::-webkit-scrollbar-track {
  background-color: white;
}

.sidebarContainer:not(.collapsed) .sidebar-content::-webkit-scrollbar-thumb,
.sidebarContainer.collapsed .sidebar-content::-webkit-scrollbar-thumb {
  background-color: #d3d9e0;
  /* Change the color as needed */
  border-radius: 10px;
}

.sidebarContainer:not(.collapsed) .sidebar-content::-webkit-scrollbar-track,
.sidebarContainer.collapsed .sidebar-content::-webkit-scrollbar-track {
  background-color: white;
  /* Change the track color as needed */
}

.sidebarContainer.collapsed .sidebar-content a .tool-tip {
  position: absolute;
  background-color: #000;
  color: #fff;
  padding: 2px 10px;
  display: none;
  font-size: 12px;
  width: 0;
  border-radius: 3px;
  transform: none;
  transition: all 0.3s;
  opacity: 0;
}

.sidebarContainer:not(.collapsed) .sidebar-content ul li a:hover {
  background-color: #e8ebed;
  border-radius: 5px;
}

.fullname_logo {
  width: 103px;
  display: none;
  margin-left: 30px;
  margin-top: -5px;
}

.sidebarContainer.collapsed .sidebar-content li:hover .tool-tip {
  display: block;
  /* right: -90px; */
  top: 27px;
  transform: translateX(80px);
  min-width: 100px;
  width: fit-content;
  opacity: 1;
}

.sidebarContainer.collapsed .sidebar-content li:hover .tool-tip::before {
  content: "";
  position: absolute;
  top: 0;
  left: -6px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 10px solid #000;
}

.sidebarContainer .sidebar-content a:not(.active) .icon {
  margin-right: 5px;
  margin-left: 0px;
  width: 40px;
  height: 40px;
  display: grid;
  place-content: center;
  /* background: #3db38926; */
  background-color: #e8ebed;
  border-radius: 5px;
}

.sidebarContainer .sidebar-content a.active p {
  color: #32c36c;
}

.sidebar-content p {
  margin-bottom: 0;
  font-size: 14px;
  color: white;
  font-weight: 500;
}

/* .sidebarContainer .sidebar-content a .icon{
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: #ddd;
    box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
    color: #264653;
    text-align: center;
    line-height: 40px;
  }
       */
.sidebar-footer {
  height: 100px;
}

.notificationsUl li {
  padding: 10px 0;
}

.notificationsUl li:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

@media (max-width: 768px) {
  .sidebarContainer.collapsed {
    width: 60px;
  }

  .mainContainer.collapsed {
    width: calc(100% - 60px);
    margin-left: 60px;
  }

  .nonetooltip,
  .desktoplogo {
    display: none !important;
  }

  .mobilelogo {
    display: block;
  }

  .mobilelogo img {
    width: 50px;
  }

  .mainContainer .makeExtend {
    display: none !important;
  }

  #closeSidebar {
    position: absolute;
    right: 0;
    border: 0;
    width: 30px;
    display: 30px;
    height: 30px;

    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    /* background: #dddddd6e; */
    padding: 0;
    z-index: 999999;
    top: 14px;
  }
}

.w-100.topbarNav.nav-bg {
  position: fixed;
  top: 0;
  height: 70px;
  background-color: aliceblue;
  line-height: 50px;
  z-index: 999999;
}

.tileclass {
  /* background-color: #051322; */
  background-color: #435585;
  width: 300px;
  height: 50px;
  color: white;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  font-size: 24px;
  margin-bottom: 0;
}

label {
  font-weight: 700;
}

.bg-blue {
  background: #051322 !important;
}

.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor,
.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-y599qu {
  background-color: #051322 !important;
  color: white !important;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-ptiqhd-MuiSvgIcon-root {
  fill: white;
}

@media (max-width: 768px) {

  .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor.grid-header,
  .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRightBorder.MuiDataGrid-withBorderColor,
  .MuiDataGrid-cell.MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRightBorder.MuiDataGrid-withBorderColor,
  .MuiDataGrid-cell.MuiDataGrid-cell--textRight.MuiDataGrid-cell--withRightBorder.MuiDataGrid-withBorderColor,
  .MuiDataGrid-cell.MuiDataGrid-cell--textLeft.MuiDataGrid-withBorderColor,
  .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor {
    height: 56px;
    width: 150px !important;
    min-width: 150 !important;
    max-width: 150px !important;
  }

  .MuiDataGrid-virtualScroller.css-qvtrhg-MuiDataGrid-virtualScroller {
    overflow: auto !important;
  }

  .responsiveheading {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .responsiveheading .flex.items-center.mt-3 {
    margin-top: 0;
  }
}

.sidebarContainer.collapsed .bg-white.d-md-block.d-none.mt-2.rounded-3.shadow {
  margin-top: 10px;
}

.sidebarContainer .sidebar-content a.active .icon img {
  position: relative;
  top: 5px;
  width: 40px;
}

.bg-green {
  background-color: #32c36c !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  /* ensure it's above other elements */
  background-color: rgb(178 173 172 / 63%);
  /* background-color: #eeeeee; */
}

.soldabs {
  position: absolute;
  right: 0;
  top: 10px;
}

.css-ptiqhd-MuiSvgIcon-root {
  color: white;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: #051322;
}

.nav-item .nav-link {
  color: black;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.MuiDataGrid-menuIconButton.css-1j7qk7u {
  color: white !important;
}

.textgradientheading h4 {
  font-size: 36px;
  /* background: -webkit-linear-gradient(#000000, #32c36c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.bgbluegrd {
  background-image: radial-gradient(circle 897px at 9% 80.3%,
      rgba(55, 60, 245, 1) 0%,
      rgba(234, 161, 15, 0.9) 100.2%);
}

.bgblackgrd {
  background-image: radial-gradient(circle 897px at 9% 80.3%,
      rgba(226, 24, 24, 1) 0%,
      rgba(234, 161, 15, 0.9) 100.2%);
}

.overauto {
  overflow: auto;
}

.textgradientheading h4 {
  font-size: 34px;
  background: -webkit-linear-gradient(#000000, #161616);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Toastify {
  z-index: 9999999999 !important;
}

.overflowscroll {
  overflow-y: auto;
  padding-bottom: 4px;
}

.informationbox h4 {
  text-transform: capitalize;
}

.icontag {

  height: 50px;
  width: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 55px;
}

.icontag i {
  font-size: 20px;
  color: white;

}

.dashbox h5 {
  font-size: 28px;
  color: black;
  font-weight: 600;
}

.dashbox h6 {
  font-size: 18px;
}

.bg-redish {
  background-color: #f95d80;
}


.bg-orange {
  background-color: #ff957b;
}

.bg-greenish {
  background-color: #46da5f;
}

.tableview .custombtn:hover {
  background-color: #3bb77e !important;
  border: 0;
  color: #fff !important;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  transition: all .1s ease-in 0s;
}

.tableview .custombtn {
  background-color: #def9ec !important;
  border: .5px solid #64c087;
  color: #64c087 !important;
  font-size: 14px !important;
}

.custombtn.active {
  background-color: #3bb77e !important;
  border: 0;
  color: #fff !important;

}


.sidebarContainer:not(.collapsed) .sidebar-content ul li a:hover p {
  color: black;
}


.pendingbtn,
.pendingbtn:hover {
  background-color: red !important;
  color: white !important;
  border: 0 !important;
  font-size: 10px !important;
  padding: 6px 14px !important;

}


.acceptbtn {
  background-color: #32c36c !important;

  color: white !important;
  font-size: 10px !important;
  padding: 6px 14px !important;
}


.bidnotification {
  position: absolute;
  top: -12px;
  right: -14px;
  height: 26px;
  width: 26px;
  font-size: 8px;
  line-height: 10px;
  background-color: #32c36c;
  border-radius: 50%;
  text-align: center;
}

.loginpage {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url("../src/assets/img/nature2.jpg");
  /* background-image: url("../src/assets/img/loginbanner.avif"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

}

.loginbox {
  background-color: #ffffff8f;
  box-shadow: 3px 13px 13px #ddd;
  padding: 10px;
}


.logincredential {
  padding: 30px;
  height: 100%;
}

.logincredential img {
  width: 100px;
  background: white;
  box-shadow: 0 0 10px;
  padding: 10px;
  border-radius: 10px;
}



.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #32c36c !important;
  border: 1px solid #32c36c !important;
  outline: 0;

}

.logincredential .input-group {
  background-color: white;
  border-radius: 4px;
}

.logincredential .btn {
  background-color: #32c36c !important;
  color: white !important;
  width: 100px;
}

.displaynonepagination .MuiDataGrid-footerContainer.MuiDataGrid-withBorderColor.css-wop1k0-MuiDataGrid-footerContainer {
  display: none;
}



.logoutfooter {
  position: fixed;
  bottom: 0;
}

.profileimage {
  height: 60px;
  width: 60px;
}

.boxcontent {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.boxcontent h5 {
  font-size: 14px;
}

.boxcontent span {
  color: #32c36c;
}


.userbox {
  background-color: red !important;
  /* Initial background color of the userbox */
  transition: background-color 0.3s ease !important;
  /* Smooth transition effect */
}

.hoverbtn:hover~.userbox {
  background-color: rgba(0, 0, 0, 0.05) !important;
  /* Adjust the opacity and color as needed */
}






















/* Converted CSS */










.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #00838d;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card1 {
  display: block;
  position: relative;
  /* max-width: 262px; */
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.card1:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #00838d;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.card1:hover:before {
  transform: scale(21);
}

.card1:hover {
  color: rgba(255, 255, 255, 0.8);
}

.card1:hover span {
  color: #ffffff !important;
}

.info {
  width: 140px;
  background: #000000d9;
  position: absolute;
  top: 54px;
  right: 30px;
  /* background: white; */
  box-shadow: 0 0 10px #ddd;
  padding: 14px;
}


.info h4 {
  font-size: 14px;
  margin-bottom: 0;
}

.info p {
  font-size: 12px;
  margin-bottom: 0;
}

.customtable thead th {
  background-color: #051322 !important;
  color: white !important;
  font-size: 14px;
  font-weight: 400;
}



.customtable tbody tr td {
  font-size: 14px;
}

.customtable tbody tr {
  vertical-align: middle;
}

.customtable tbody tr {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.customtable tbody td {
  border: 1px solid #e0e0e0;

}

.customtable tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.customtable {
  font-family: "Jost", sans-serif;
}



.customtable b {
  font-weight: 500;
}

.dashbox p {
  color: #808080;
  font-size: 16px;
}

.dashbox {
  background-color: white;
  padding: 16px;
  border-radius: 10px;
}

.dropdown-menu {
  padding: 0 !important;
}

.dropdown-item .btn {
  font-size: 14px !important;
}

.badge {
  letter-spacing: 1px;
  font-weight: 500 !important;
  font-size: 12px;
  text-transform: capitalize;
}

@media (max-width: 768px) {

  .table-responsive>.table>thead>tr>th,
  .table-responsive>.table>tbody>tr>th,
  .table-responsive>.table>tfoot>tr>th,
  .table-responsive>.table>thead>tr>td,
  .table-responsive>.table>tbody>tr>td,
  .table-responsive>.table>tfoot>tr>td {
    white-space: nowrap;
  }
}

.table-responsive>.table>thead>tr>th,
.table-responsive>.table>tbody>tr>th,
.table-responsive>.table>tfoot>tr>th,
.table-responsive>.table>thead>tr>td,
.table-responsive>.table>tbody>tr>td,
.table-responsive>.table>tfoot>tr>td {
  white-space: nowrap;
}

.searchbox input {
  background-color: transparent;
  border: 0;
  padding: 6px 9px;

}

.searchbox input:focus,
.searchbox input:focus-visible {
  border-color: transparent !important;
}



.searchbox i {
  position: relative;
  top: 10px;
  padding: 2px 13px;
}

.searchbox {
  background-color: white;

}

.highcharts-credits {
  display: none !important;
}

.mainboxdetail {
  background: #1a8e25;
}

.boxdetail h4 {
  margin-bottom: 0;
  font-size: 16px;
}

.boxdetail p {
  font-size: 16px;
  margin-bottom: 0;
}




.switch-container {
  display: flex;
  align-items: center;
}

.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
  margin-right: 10px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(182, 182, 182);
  transition: .4s;
  border-radius: 10px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  border-radius: 8px;
  left: 0.3em;
  bottom: 0.3em;
  transform: rotate(270deg);
  background-color: rgb(255, 255, 255);
  transition: .4s;
}

.switch input:checked+.slider {
  background-color: #21cc4c;
}

.switch input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

.switch input:checked+.slider:before {
  transform: translateX(1.5em);
}

.label-text {
  font-size: 1em;
  font-weight: bold;
}